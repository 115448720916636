import { ReactComponent as css} from './css.svg';
import { ReactComponent as html} from './html.svg';
import { ReactComponent as javscript} from './javscript.svg';
import { ReactComponent as react} from './react.svg';
import { ReactComponent as java} from './java.svg';
import { ReactComponent as python} from './python.svg';
import { ReactComponent as xd} from './xd.svg';
import { ReactComponent as cplusplus} from './cplusplus.svg';
import { ReactComponent as nodejs} from './nodejs.svg';
import { ReactComponent as sql} from './sql.svg';
import { ReactComponent as github} from './github.svg';
import { ReactComponent as eye} from './eye.svg';


export default{
    css,
    html,
    javscript,
    react,
    java,
    python,
    xd,
    cplusplus,
    nodejs,
    sql,
    github,
    eye
}